import React from "react"

import { withLoginForm } from "./withLoginForm"
import { Form, Description, Field, Button, ButtonWrapper, ErrorMessage, SignIn, SignInLink } from "../../Form/Form.styled"
import { SocialLogins } from "../../SocialLogins/SocialLogins"

export const LoginForm = withLoginForm(
  ({ data, handleChange, handleSubmit, loading, errors, content, membershipActive }: Props): JSX.Element => (
    <Form onSubmit={handleSubmit}>
      {content && !errors?.length ? <Description>{content}</Description> : null}
      {errors?.length > 0 && (
        <Description>
          <ul>
            {errors?.map((error, index) => (
              <li key={index}>
                <ErrorMessage>{error?.message}</ErrorMessage>
              </li>
            ))}
          </ul>
        </Description>
      )}
      <Field
        name={"email"}
        type={"email"}
        placeholder={"Email address"}
        value={data?.email}
        onChange={handleChange}
        required
        error={errors?.filter(error => error?.field?.includes("email"))?.length}
      />
      <Field
        name={"password"}
        type={"password"}
        placeholder={"Password"}
        value={data?.password}
        onChange={handleChange}
        required
        error={errors?.filter(error => error?.field?.includes("password"))?.length}
      />
      <ButtonWrapper center={true}>
        <Button
          type={`submit`}
          size={`large`}
          colour={`dark`}
          theme={`primary`}
          center={true}
          loading={loading}
          disabled={data?.email?.length < 6 || data?.password?.length < 8}
        >
          {loading ? "Loading..." : "Login"}
        </Button>
        {/* <SocialLogins /> */}
      </ButtonWrapper>

      <SignIn>
        Don't have an account?{" "}
        <SignInLink theme={`primary`} to={`/account/register`}>
          {membershipActive ? "Join now" : "Join wait list"}
        </SignInLink>{" "}
        or{" "}
        <SignInLink theme={`primary`} to={`/account/forgot`}>
          reset password.
        </SignInLink>
      </SignIn>
    </Form>
  )
)

export interface Props {
  data: any
  content: any
  handleChange: () => void
  handleSubmit: () => void
  loading: boolean
  errors: Array<any>
  membershipActive?: boolean
}
