import React, { useMemo } from "react"

import { useCore } from "../../../hooks/useCore"

export const withLogin = Component =>
  React.memo(({ name = "Login", page }) => {
    const {
      helpers: { sanityContent },
    } = useCore()

    const title = page?.title
    const content = sanityContent(page?.content)

    Component.displayName = name
    return useMemo(() => <Component title={title} content={content} />, [])
  })
