import React from "react"
import { navigate } from "gatsby"

import { useCore } from "../hooks/useCore"
import { useApp } from "../hooks/useApp"
import { useCustomerAccessToken } from "../hooks/useCustomer"

export const withAuthentication = Component => ({ ...props }) => {
  const {
    helpers: { storage },
  } = useCore()
  const {
    ready,
    customer,
    setRedirectUrl,
    config: {
      settings: { keys, routes },
    },
  } = useApp()
  const { getCustomer } = useCustomerAccessToken()
  const customerTokens = storage.get(keys?.customer)
  const redirect = (!customerTokens?.accessToken || new Date(customerTokens?.expiresAt) <= new Date()) && typeof window !== "undefined"

  if (redirect && ready) {
    storage.remove(keys?.customer)
    setRedirectUrl(window.location.pathname)
    navigate(routes?.LOGIN, { replace: true })
  }

  if (!customer?.id) getCustomer()

  return !redirect ? <Component {...props} /> : null
}

export const withoutAuthentication = Component => ({ ...props }) => {
  const {
    helpers: { storage },
  } = useCore()
  const {
    ready,
    config: {
      settings: { keys, routes },
    },
  } = useApp()
  const customerTokens = storage.get(keys?.customer)
  const redirect = customerTokens?.accessToken && new Date(customerTokens?.expiresAt) >= new Date() && typeof window !== "undefined"

  if (redirect && ready) navigate(routes?.DASHBOARD)

  return !redirect ? <Component {...props} /> : null
}
