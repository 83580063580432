import React from "react"

import { withLogin } from "./withLogin"
import { LoginForm } from "./LoginForm"
import { Container, Frame, Title } from "../../Form/Form.styled"

export const Login = withLogin(
  ({ title, content }): JSX.Element => (
    <Container width={`md`}>
      <Frame>
        <Title description={content}>{title}</Title>
        <LoginForm
          content={content}
          defaultView={"login"}
          views={{
            login: {
              title,
              content,
              buttonText: `Login`,
              buttonView: `password`,
              linkText: `Don't have an account? Sign up`,
              link: `/account/register`,
              social: false,
              fields: [
                {
                  name: `email`,
                  placeholder: `Email address`,
                  type: `email`,
                },
              ],
            },
            password: {
              title,
              content,
              buttonText: `Login`,
              buttonLink: `/account/dashboard`,
              linkText: `Forgot password?`,
              link: `/account/forgot`,
              fields: [
                {
                  name: `password`,
                  placeholder: `Password`,
                  type: `password`,
                },
              ],
            },
          }}
        />
      </Frame>
    </Container>
  )
)
