import React from "react"
import { graphql } from "gatsby"

import { withoutAuthentication } from "../../hoc/withAuthentication"
import { Login as Page } from "../../components/Acccount/Login/Login"

export const query = graphql`
  query {
    page: sanityPageAccountLogin {
      title
      metadata: _rawMetadata(resolveReferences: { maxDepth: 10 })
      content: _rawContent(resolveReferences: { maxDepth: 10 })
    }
  }
`

export default withoutAuthentication(({ data, ...props }) => <Page {...props} {...data} />)
