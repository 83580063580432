import React, { useState, useMemo } from "react"

import { useCustomerLogin } from "../../../hooks/useCustomer"
import { useSettings } from "../../../hooks/useSettings"

export const withLoginForm = Component =>
  React.memo(({ name = "LoginForm", views = {}, ...props }) => {
    const { loginCustomer, data, setData, loading, errors } = useCustomerLogin()
    const { membership } = useSettings()

    const handleSubmit = async event => {
      event.preventDefault()
      await loginCustomer({
        ...data,
      })
    }

    const handleChange = ({ target: { type, name, value, checked } }) => {
      setData(prevState => ({
        ...prevState,
        [name]: type === "checkbox" ? checked : value,
      }))
    }

    const errorMessages = errors?.map(error => ({
      ...error,
      message: error?.message?.replace("Unidentified customer", "Email or password not recognised. Please try again.") || error,
    }))

    Component.displayName = name
    return useMemo(
      () => (
        <Component
          {...props}
          data={data}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          loading={loading}
          errors={errorMessages}
          membershipActive={membership?.enabled}
        />
      ),
      [data, loading, errors]
    )
  })
